import React, { useState } from 'react';
import axios from 'axios';
import styles from './UploadImage.module.css';

const UploadImage = ({ onUploadSuccess }) => {
  const [name, setName] = useState('');
  const [generational, setGenerational] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [race, setRace] = useState('');
  const [summary, setSummary] = useState('');
  const [voice, setVoice] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [message, setMessage] = useState('');

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImage(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleSubmit = async () => {
    if (!name || !image) {
      console.error('Name and image are required');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.onload = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const pngDataUrl = canvas.toDataURL('image/png');

        try {
          const response = await axios.post(process.env.REACT_APP_ADD, {
            name,
            image: pngDataUrl.replace('data:image/png;base64,', ''),
            generational,
            age: parseInt(age),
            gender,
            race,
            summary,
            voice,
            category: category.split(',')
          });
          setMessage('Person added');
          onUploadSuccess();
        } catch (error) {
          console.error('Error uploading the image:', error);
        }
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(image);
  };

  const resetForm = () => {
    setName('');
    setGenerational('');
    setAge('');
    setGender('');
    setRace('');
    setSummary('');
    setVoice('');
    setCategory('');
    setImage(null);
    setImagePreview(null);
    setMessage('');
  };

  return (
    <div>
      {message ? (
        <div>
          <p>{message}</p>
          <button onClick={resetForm}>Add another?</button>
        </div>
      ) : (
        <div>
          <div
            className={styles.dropzone}
            onClick={() => document.getElementById('fileInput').click()}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              type="file"
              id="fileInput"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
            {imagePreview ? (
              <img src={imagePreview} alt="Preview" />
            ) : (
              <p>Drag & drop an image, or click to select one</p>
            )}
          </div>
          <input
            className={styles.inputField}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
          <input
            className={styles.inputField}
            type="text"
            value={generational}
            onChange={(e) => setGenerational(e.target.value)}
            placeholder="Generational"
          />
          <input
            className={styles.inputField}
            type="number"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            placeholder="Age"
          />
          <input
            className={styles.inputField}
            type="text"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            placeholder="Gender"
          />
          <input
            className={styles.inputField}
            type="text"
            value={race}
            onChange={(e) => setRace(e.target.value)}
            placeholder="Race"
          />
          <input
            className={styles.inputField}
            type="text"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
            placeholder="Summary"
          />
          <input
            className={styles.inputField}
            type="text"
            value={voice}
            onChange={(e) => setVoice(e.target.value)}
            placeholder="Voice"
          />
          <input
            className={styles.inputField}
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            placeholder="Category (comma separated)"
          />
          <button
            className={styles.button}
            onClick={handleSubmit}
            disabled={!name || !image}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
