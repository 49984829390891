// ShowPeople.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './ShowPeople.module.css';

const ShowPeople = () => {
  const [people, setPeople] = useState([]);

  const fetchPeople = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SHOW);
      setPeople(response.data);
    } catch (error) {
      console.error('Error fetching people:', error);
    }
  };

  useEffect(() => {
    fetchPeople();
  }, []);

  return (
    <div>
      <button className={styles.refreshButton} onClick={fetchPeople}>Refresh</button>
      <div>
        {people.map((person) => (
          <div key={person._id} className={styles.personContainer}>
            <h3>{person.generational}</h3>
            <p>Age: {person.age}</p>
            <p>Gender: {person.gender}</p>
            <p>Race: {person.race}</p>
            <p>Summary: {person.summary}</p>
            <p>Voice: {person.voice}</p>
            <p>Category: {person.category.join(', ')}</p>
            <img src={`data:image/jpeg;base64,${person.image}`} alt={person.generational} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShowPeople;
