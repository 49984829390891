import React, { useState } from 'react';
import UploadImage from './UploadImage';
import ShowPeople from './ShowPeople';

const App = () => {
  const [refresh, setRefresh] = useState(false);

  const handleUploadSuccess = () => {
    setRefresh(!refresh);
  };

  return (
    <div>
      <UploadImage onUploadSuccess={handleUploadSuccess} />
      <ShowPeople key={refresh} />
    </div>
  );
};

export default App;
